const logotext = "Yogesh Meena";
const meta = {
    title: "Yogesh Meena",
    description: "I’m Yogesh Meena Cloud Engineer _ Security Enthusiastic, currently pursuing MSc Information Security at Royal Holloway University Of London",
};

const introdata = {
    title: "I’m Yogesh Meena",
    animated: {
        first: "A Computer Engineer",
        second: "A Cloud Enthusiastic",
        third: "A Freelancer", 
        fourth: "A Photographer"
    },
    description: "A Professional DevOps Engineer with Bachelor's in Computer Science Engineering (Cybersecurity) and Open Source Technologies. Pursuing MSc. in Information Security from Royal Holloway University of London.",
    your_img_url: "https://images.unsplash.com/photo-1514790193030-c89d266d5a9d",
};

const dataabout = {
    title: "abit about my self",
    aboutme: "Hello! My name is Yogesh and I enjoy creating things that live on the internet. My interest is in Cybersecurity, Cloud Technology, Web development, etc. My major interest is in automation and security, back in 2017 i started my passion with creating an Auto Blog. Fast-forward to today, now i enjoy working on Cloud Technologies, bash scripts, IAC(terraform), etc.",
};
const worktimeline = [{
        jobtitle: "DevOps Engineer",
        where: "Techolution India",
        date: "Oct 2021 - Sept 2022 (1 year)",
    },
    {
        jobtitle: "Web Developer",
        where: "iOTA Infotech Pvt. Ltd.",
        date: "Aug 2021 - Oct 2021 (3 months)",
    },
    {
        jobtitle: "Digital Marketing Executive",
        where: "iOTA Infotech Pvt. Ltd.",
        date: "Jul 2021 - Oct 2021 (4 months)",
    },
    {
        jobtitle: "Network Engineer Intern",
        where: "Znet Network Solutions",
        date: "Dec 2020 - Jun 2021 (7 months)",
    },
    {
        jobtitle: "Professional Freelancer",
        where: "Freelance Web Developer / Designer",
        date: "Dec 2017 - Nov 2020 (3 years)",
    },
];

const skills = [{
        name: "Linux",
        value: 90,
    },
    {
        name: "Google Cloud",
        value: 85,
    },
    {
        name: "CI/CD",
        value: 85,
    },
    {
        name: "Python",
        value: 75,
    },
    {
        name: "C++",
        value: 70,
    },
    {
        name: "Bash",
        value: 80,
    },
    {
        name: "Digital Marketing",
        value: 90,
    },
];

const projects = [{
        title: "UI & UX Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Mobile Apps",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Wordpress Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
];

const dataportfolio = [{
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/800/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/600/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
];

const contactConfig = {
    YOUR_EMAIL: "yogesh@geekymahar.com",
    YOUR_FONE: "+44 743695912*",
    description: "My inbox is always open. Whether you have a question or just want to say hi, I’ll try my best to get back to you!",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_id",
    YOUR_TEMPLATE_ID: "template_id",
    YOUR_USER_ID: "user_id",
};

const socialprofils = {
    github: "https://github.com/geekymahar",
    facebook: "https://facebook.com/yogesh20081999",
    linkedin: "https://linkedin.com/in/geekymahar",
    twitter: "https://twitter.com/geekymahar",
    instagram: "https://instagram.com/geekymahar",
    twitch: "https://twitch.com",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    projects,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};
